<template>
  <div class="content-wrap">
    <div class="content-select">
      <div class="select-header">
        <div class="select-title">
          <span>岗位管理</span>
        </div>
        <div class="select-items" v-role="'RS-M01-F01'" @click="handleInsertRole">
          <img src="@/assets/icon/user-manage/role-insert.png"/>
          <span>新增岗位</span>
        </div>
      </div>
      <div class="select-content">
        <div class="select-item">
          <span>岗位名称</span>
          <el-input
            v-model.trim="search.name"
            placeholder="请输入..."
            maxlength="16" @keyup.enter.native="refreshTableData()"
          />
        </div>
        <el-button type="primary" :disabled="submitDisabled" @click="refreshTableData">查询</el-button>
      </div>
    </div>
    <div class="content-main">
      <div class="content-items">
        <div class="item-table">
          <div class="item-title">岗位列表</div>
          <div class="content-table">
            <el-table
              :data="tableData"
              class="user-table"
              border
              stripe
              highlight-current-row
              @current-change="handleCurrentRecordChange"
              :header-cell-style="handleHeaderCellStyle"
              @cell-mouse-enter="handleCellEnter"
              @cell-mouse-leave="handleCellLeave"
              style="width: 100%"
            >
              <el-table-column type="index" label="序号" width="60"/>
              <el-table-column prop="name" label="岗位名称"/>
              <el-table-column prop="dataScopeType" :formatter="fmtDataScopeType" label="数据权限" width="100"/>
              <el-table-column prop="desc" label="备注"/>
              <el-table-column prop="operate" label="操作" width="110">
                <template slot-scope="scope">
                  <el-button
                    type="text" size="medium" v-role="'RS-M01-F02'"
                    :disabled="scope.row.isSystematic"
                    @click="handleUpdateRole(scope.row)">编辑
                  </el-button>
                  <el-button
                    type="text" size="medium" v-role="'RS-M01-F03'"
                    :disabled="scope.row.isSystematic"
                    @click="handleDeleteRole(scope.row)">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="item-tree">
          <div class="item-title">
            <span>功能权限</span>
            <div>
              <el-button type="primary" @click="handleSaveMode">保存</el-button>
            </div>
          </div>
          <el-tree
            :data="treeData"
            :props="props"
            :check-strictly="checkStrictly"
            node-key="id"
            :render-after-expand="renderExpand"
            :default-checked-keys="defaultCkeckedKeys"
            @check-change="handleCheckNode"
            show-checkbox
          >
          </el-tree>
        </div>
      </div>
      <div class="content-page">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page.current"
          :page-sizes="pageSizes"
          :page-size="page.size"
          layout="total, sizes, prev, pager, next"
          :total="page.total">
        </el-pagination>
      </div>
    </div>
    <!-- 新增岗位弹窗 -->
    <el-dialog append-to-body :title="roleMessage" :visible.sync="dialogFormVisible" width="880px" @close="handleRoleAddPopupCancel">
      <div class="role-insert-popup">
        <div class="popup-first">
          <div class="insert-col">
            <div class="required">岗位名称</div>
            <el-input v-model="form.name" placeholder="请输入岗位" maxlength="16"/>
          </div>
          <div class="insert-col">
            <div class="required">数据权限</div>
            <el-select v-model="form.dataScopeType" style="width: 400px;">
              <el-option label="全部" value="AA"/>
              <el-option label="本人数据" value="OO"/>
              <el-option label="本级" value="CL"/>
              <el-option label="自定义" value="CS"/>
            </el-select>
          </div>
        </div>
        <div class="popup-second" v-if="organizationTreeVisible">
          <div class="second-col">
            <div class="required">数据范围</div>
            <el-tree
              :data="organizationTree"
              ref="organizationTree"
              :props="props"
              :check-strictly="checkStrictly"
              node-key="id"
              :render-after-expand="renderExpand"
              :default-checked-keys="form.orgIds"
              @node-click="handleOrganizationClick"
              @check-change="handleOrganizationSelected"
              show-checkbox
              accordion
            >
            </el-tree>
          </div>
          <div class="second-col">
            <div>税控设备权限</div>
            <el-select v-model="organization.taxScopeType" :disabled="organization.taxScopeTypeDisabled"
                       style="width: 400px;">
              <el-option label="全部" value="AA"/>
              <el-option label="本级" value="CL"/>
              <el-option label="自定义" value="CS"/>
            </el-select>
            <div class="equipment" v-if="taxControlEquipmentListVisible">
              <el-checkbox-group v-model="equipmentList">
                <el-checkbox
                  v-for="(equipment,index) in organization.allEquipments"
                  :key="index"
                  v-model="equipment.id"
                  :label="equipment.id"
                  @change="handleEquipmentSelect(equipment,$event)"
                >{{'分机编号：' + equipment.extensionNo}}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div class="popup-third">
          <span>备注</span>
          <el-input type="textarea" v-model="form.desc" maxlength="100" placeholder="备注信息"/>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleRoleAddPopupCancel">取 消</el-button>
        <el-button type="primary" :disabled="dialogLoading" @click="handleSaveRole">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { tableStyle, pagingMethods } from '@/util/mixins'
import { getListByPage, getFuncScope, saveFuncScope, saveRole, getRole, deleteRole } from '@/service/role'
import { getManagerOrganizationTree } from '@/service/organization'
import { listEquipmentByOrg } from '@/service/invoice-open'

const map = new Map()
map.set('CS', '自定义')
map.set('AA', '全部')
map.set('CL', '本级')

export default {
  name: 'RoleConfig',
  mixins: [tableStyle, pagingMethods],
  data () {
    return {
      submitDisabled: false,      //查询点击控制
      dialogFormVisible: false,   //岗位信息弹出层
      dialogLoading: false,       //岗位信息相关loading控制
      renderExpand: false,        //tree组件是否在第一次展开节点后渲染
      checkStrictly: true,        //tree组件父子节点 不 互相关联
      isInsert: true,             //区分新增修改，dialog文本
      search: {
        name: ''                //筛选岗位名称
      },
      form: {                     //岗位信息表单
        id: 0,
        name: '',
        level: 1,
        dataScopeType: 'AA',
        taxScopeType: 'AA',
        organizations: [],
        desc: ''
      },
      treeData: [],               //功能权限树
      organizationTree: [],       //数据范围树
      defaultCkeckedKeys: [],     //功能权限默认被选中节点
      defaultOrgCkeckedKeys: [],  //数据范围默认被选中节点（编辑）
      props: {                    //tree组件对应
        children: 'children',
        label: 'name'
      },
      roleId: null,                //当前被选中的岗位
      tableData: [],              //岗位表格数据
      equipmentList: [],
      organization: {
        id: 0,
        taxScopeTypeDisabled: true,
        taxScopeType: 'AA',
        scopeEquipments: [],
        allEquipments: []
      },
      taxScopeType: 'AA'
    }
  },
  computed: {
    roleMessage () {
      return this.isInsert == true ? '新增岗位' : '修改岗位'
    },
    organizationTreeVisible () {
      return this.form.dataScopeType == 'CS' ? true : false
    },
    taxControlEquipmentListVisible () {
      return this.organization.taxScopeType == 'CS' ? true : false
    }
  },
  created () {
    this.refreshTableData()
    /* 初始化数据权限树（根据租户） */
    getManagerOrganizationTree().then(({ success, data }) => {
      if (success) {
        this.organizationTree = data
      }
    })
  },
  methods: {
    /* 功能树渲染 */
    async refreshTreeData (value) {
      this.treeData = []
      const { success, data } = await getFuncScope({ 'roleId': value })
      this.defaultCkeckedKeys = []
      if (success) {
        this.treeData = this.fmtFuncScopeTree(data.scope)
      }
    },
    /* 岗位表格渲染 */
    async refreshTableData () {
      this.tableData = []
      const { success, data } = await getListByPage({ filter: this.search, paging: this.page })
      if (success) {
        this.tableData = data.records
        this.page.total = data.total
        this.refreshTreeData(0)
      }
    },
    submitForm () {
      this.refreshTableData()
    },
    /* 表格单选 */
    handleCurrentRecordChange (row) {
      this.roleId = row.id
      this.refreshTreeData(row.id)
    },
    /* 点击新增岗位 */
    handleInsertRole () {
      this.form = {
        id: 0,
        name: '',
        level: 1,
        dataScopeType: 'AA',
        orgIds: [],
        desc: '',
        organizations: []
      }
      this.isInsert = true
      this.dialogFormVisible = true
    },
    /* 组织点击事件 */
    async handleOrganizationClick (organization) {
      const index = this.form.organizations.findIndex(item => item.id == organization.id)
      if (index != -1) {
        const { success, data } = await listEquipmentByOrg(organization.id)
        if (success) {
          this.organization = this.form.organizations[index]
          this.organization.allEquipments = data
          if(this.taxScopeType != null){
            this.organization.taxScopeType = this.taxScopeType
          }
        }
      } else {
        this.organization = {
          id: organization.id,
          taxScopeTypeDisabled: true,
          taxScopeType: 'AA',
          scopeEquipments: [],
          allEquipments: []
        }
      }
    },
    /* 组织选中事件 */
    async handleOrganizationSelected (organization, isSelected) {
      const { success, data } = await listEquipmentByOrg(organization.id)
      if (isSelected) {
        if (success) {
          let taxScopeType = 'CS'
          let taxScopeTypeDisabled = false
          if (data.length == 0) {
            taxScopeType = 'AA'
            taxScopeTypeDisabled = true
          }
          this.taxScopeType = this.taxScopeType == null || this.taxScopeType == '' ? taxScopeType : this.taxScopeType;
          this.organization = {
            id: organization.id,
            taxScopeTypeDisabled: taxScopeTypeDisabled,
            taxScopeType: this.taxScopeType,
            scopeEquipments: [],
            allEquipments: data
          }
          this.form.organizations.push(this.organization)
        }
      } else {
        this.form.organizations.splice(this.form.organizations.findIndex(item => item.id == organization.id), 1)
        data.forEach(item => {
          const index = this.equipmentList.findIndex(equipment => equipment == item.id)
          if (index != -1) this.equipmentList.splice(index, 1)
        })
        this.organization = {
          id: 0,
          taxScopeTypeDisabled: true,
          taxScopeType: 'AA',
          scopeEquipments: [],
          allEquipments: []
        }
      }
    },
    /* 设备权限选中 */
    handleEquipmentSelect (equipment, isSelected) {
      const index = this.form.organizations.findIndex(item => item.id == equipment.orgId)
      if (isSelected) {
        if (index != -1) {
          this.form.organizations[index].scopeEquipments.push(equipment)
        }
      } else {
        const equIndex = this.form.organizations[index].scopeEquipments.findIndex(item => item.id == equipment.id)
        if (equIndex != -1) {
          this.form.organizations[index].scopeEquipments.splice([equIndex], 1)
        }
      }
    },
    /* 岗位弹窗取消 */
    async handleRoleAddPopupCancel () {
      this.dialogFormVisible = false
      this.equipmentList = []
      this.$refs.organizationTree.setCheckedKeys([]);
      this.organization = {
        id: 0,
        taxScopeTypeDisabled: true,
        taxScopeType: 'AA',
        scopeEquipments: [],
        allEquipments: []
      }
    },
    /* 岗位保存 */
    async handleSaveRole () {
      if (this.form.dataScopeType == 'CS') {
        this.form.orgIds = this.$refs.organizationTree.getCheckedKeys()
        if (this.form.orgIds.length < 1) {
          this.toast('自定义岗位不可为空', 'warning')
          return
        }
      }
      this.dialogLoading = true
      if (!this.form.name) {
        this.toast('岗位名称不可为空', 'warning')
        this.dialogLoading = false
        return
      }
      this.form.taxScopeType = this.organization.taxScopeType
      const res = await saveRole(this.form)
      if (res.success) {
        this.dialogFormVisible = false
        this.dialogLoading = false
        this.toast('岗位信息保存成功', 'success', () => this.refreshTableData())
      }
      this.dialogLoading = false
    },
    /* 岗位信息修改 */
    async handleUpdateRole (value) {
      this.$nextTick(() => {
        this.$refs.organizationTree.setCheckedKeys([])
      })
      const { data } = await getRole({ id: value.id })
      this.organizationTree = this.changeRoleChecked(this.organizationTree, data.orgIds)
      this.form = data
      if (data.organizations.length != 0) {
        data.organizations.forEach(organization => {
          // 设置选中设备
          if (organization.scopeEquipments != null) {
            const equipmentIds = organization.scopeEquipments.map(item => item.id)
            this.equipmentList = [...new Set(this.equipmentList.concat(equipmentIds))]
          }
        })
      }
      this.taxScopeType = data.taxScopeType
      this.isInsert = false
      this.dialogFormVisible = true
    },
    /* 删除所选岗位 */
    async handleDeleteRole (value) {
      const res = await deleteRole({ id: value.id })
      if (res.success) {
        this.toast('所选岗位删除成功', 'success', () => this.refreshTableData())
      }
    },
    /* 父节点单向关联 */
    handleCheckNode (node, checked) {
      this.changeRoleFuncChecked(this.treeData, checked, node.id)
    },
    /* 保存岗位功能范围 */
    async handleSaveMode () {
      if (!this.roleId) {
        this.toast('请先选择对应岗位', 'warning', () => this.refreshTreeData(0))
      }
      const res = await saveFuncScope({ roleId: this.roleId, scope: [...this.treeData] })
      if (res.success) {
        this.toast('保存成功', 'success', () => this.refreshTreeData(this.roleId))
      }
    },
    /* 格式化数据范围类型 */
    fmtDataScopeType (R, I, cellValue) {
      return map.get(cellValue)
    },
    /* 格式化功能树 */
    fmtFuncScopeTree (treeModel = []) {
      treeModel.forEach(i => {
        if (i.checked) {
          this.defaultCkeckedKeys.push(i.id)
        }
        i.name = i.name.substring(i.name.lastIndexOf('-') + 1)
        if (i.children.length > 0) {
          this.fmtFuncScopeTree(i.children)
        }
      })
      return treeModel
    },
    changeRoleChecked (model = [], nodes = []) {
      model.forEach(i => {
        if (nodes.indexOf(i.id) > -1) {
          i['checked'] = true
        } else {
          i['checked'] = false
        }
        if (i.children.length > 0) {
          this.changeRoleChecked(i.children, nodes)
        }
      })
      return model
    },
    /* 功能权限树 */
    changeRoleFuncChecked (model, checked, nodeId) {
      model.forEach(i => {
        if (nodeId == i.id) {
          i.checked = checked
        }
        if (i.children.length > 0) {
          this.changeRoleFuncChecked(i.children, checked, nodeId)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .select-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;
    padding: 16px 24px;

    .select-items {
      display: flex;
      align-items: center;

      img {
        width: 18px;
        height: 18px;
        vertical-align: sub;
        margin-right: 4px;
      }

      span {
        color: #3d94ff;
        margin-right: 20px;
        font-weight: 500;
        font-size: 16px;
      }

      span:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .select-content {
    display: flex;
    align-items: center;

    .select-item {
      margin-right: 60px;

      span {
        margin-right: 10px;
      }
    }
  }


  .select-title span:before {
    content: "|";
    display: inline-block;
    font-size: 16px;
    margin-right: 10px;
    background-color: #333;
  }

  .content-main {
    min-height: calc(100% - 170px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .content-table {
      .el-button {
        padding: 0;
      }
    }

    .content-items {
      display: flex;
      height: 100%;
      flex-direction: row;
      justify-content: space-between;

      .item-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 24px;
        height: 32px;
        line-height: 32px;
        color: #333333;
        font-size: 16px;
        font-weight: 500;
        border: 1px solid #e5e5e5;
      }

      .item-table {
        display: flex;
        flex-direction: column;
        width: calc(100% - 440px);
      }

      .item-tree {
        width: 380px;
        border: 1px solid #e5e5e5;
      }
    }
  }

  ::v-deep .el-dialog {
    width: 480px;
  }

  ::v-deep .el-dialog__body {
    padding: 0 20px;
    text-align: left;
    font-size: 14px;
  }

  .required:before {
    content: '*';
    color: #B60000;
  }

  .role-insert-popup {
    .popup-first {
      display: flex;
      justify-content: space-between;
      margin-top: 32px;

      .insert-col {
        .el-input {
          width: 400px;
          margin-top: 8px;
        }

        .el-select {
          margin-top: 8px;

          ::v-deep .el-input__inner {
            width: 400px !important;
          }

          ::v-deep .el-input__suffix {
            left: 370px;
          }
        }
      }
    }

    .popup-second {
      display: flex;
      justify-content: space-between;
      margin-top: 28px;

      .second-col {

        .equipment {
          width: 400px;
          min-height: 164px;
          border: 1px solid #E5E5E5;
          border-radius: 4px;
          margin-top: 16px;

          .el-checkbox-group {
            display: flex;
            flex-direction: column;
            padding: 8px;

            .el-checkbox {
              margin-bottom: 8px;
            }
          }
        }

        .el-tree {
          width: 382px;
          min-height: 196px;
          padding: 8px;
          margin-top: 8px;
          border: 1px solid #e5e5e5;
          border-radius: 4px;
        }

        .el-select {
          margin-top: 8px;

          ::v-deep .el-input__inner {
            width: 400px !important;
          }

          ::v-deep .el-input__suffix {
            left: 370px;
          }
        }
      }
    }

    .popup-third {
      margin-top: 28px;

      .el-textarea {
        margin-top: 8px;
      }
    }
  }
</style>
